import Vue from 'vue'
import VueRouter from 'vue-router'
import { storeToRefs } from "pinia";
import { useloggedUserInfo, moduleStore } from "../stores/UserInfo";
import Callback from '../views/auth/Callback.vue'
import Unauthorized from '../views/auth/Unauthorized.vue'
import authService from '../services/authservice';
import DataService from "../services/DataService";
import common from '../assets/common/vue-common.js'
Vue.use(VueRouter)
const routes = [
    {
        path: '/unauthorized',
        name: 'Unauthorized',
        component: Unauthorized
    },
    {
        path: "/JobPost/JobListing",
        name: "JobList",
        component: () => import('../views/HR/JobPost/JobList.vue'),
        meta: {
            checkPrivilege: false,
            title: 'Job Listing',
            breadcrumb: [{ name: "Job Listing" }]
        }
    },
    {
        path: "/JobPost/RequisitionList",
        name: "JobRequisitionList",
        component: () => import('../views/HR/JobPost/RequisitionList.vue'),
        meta: {
            checkPrivilege: false,
            title: 'Job Requisition List',
            breadcrumb: [{ name: "Requisition List" }]
        }
    },
    {
        path: "/JobPost/RequisitionDetail/:requisitionId",
        name: "JobRequisitionDetail",
        component: () => import('../views/HR/JobPost/RequisitionDetail.vue'),
        meta: {
            checkPrivilege: false,
            title: 'Job Requisition List',
            breadcrumb: [{ name: "Requisition Detail" }]
        }
    },
    {
        path: "/JobPost/AddRequisition/:requisitionId",
        name: "AddRequisition",
        component: () => import('../views/HR/JobPost/AddRequisition.vue'),
        meta: {
            userguide: 'User_Guide_Add_Job_Requisition',
            checkPrivilege: false,
            title: 'Add Requisition',
            breadcrumb: [{ name: "Add Requisition" }]
        }
    },
    {
        path: "/JobPost/EditRequisition/:requisitionId",
        name: "EditRequisition",
        component: () => import('../views/HR/JobPost/AddRequisition.vue'),
        meta: {
            checkPrivilege: false,
            title: 'Edit Requisition',
            breadcrumb: [{ name: "Edit Requisition" }]
        }
    },
    {      
        path: "/JobPost/ManageJob/:id/:moduleName/:subModuleCode/:reqType?",
        name: "HRManageJobPost",
        component: () => import( "../views/HR/JobPost/ManageJobPostCustomLayout.vue"),
        meta: { 
            checkPrivilege: false,
            title: 'Manage JobPost',
        breadcrumb:[
            {name:"JobPost", link:"/JobPost/JobListing"},
            {name: "Manage"}
        ],
        actionName:'ManageJobPost'}
    },
    {
        path: "/HR/GroupListing",
        name: "GroupListing",
        component: () => import('../views/HR/Group/GroupList.vue'),
        meta: {
            title: 'Group Listing',
            breadcrumb: [{ name: "Group Listing" }]
        }
    },
    {
        path: "/HR/TalentPoolList",
        name: "TalentPoolList",
        component: () => import('../views/HR/TalentPool/List.vue'),
        meta: {
            title: 'Talent Pool List',
            breadcrumb: [{ name: "Talent Pool List" }]
        }
    },
    {
        path: "/HR/CandidateList",
        name: "CandidateList",
        component: () => import('../views/HR/Candidate/Candidate.vue'),
        meta: {
            title: 'Candidate List',
            breadcrumb: [{ name: "Candidate List" }]
        }
    },
    {
        path: "/HR/ManageCandidate/:reqfrom?/:id?/:pid?/:aid?/:uid?",
        name: "ManageCandidate",
        component: () => import( "../views/HR/Candidate/AddCandidate.vue"),
        meta: { title: 'Add Candidate',
        breadcrumb:[
            {name:"Candidate Listing", link:"/HR/CandidateList"},
            {name: "Manage"}
        ]
    }       
    },
    {
        path: "/HR/AddAsCandidate/:id",
        name: "HRAddAsCandidate",
        component: () => import( "../views/HR/TalentPool/AddAsCandidate.vue"),
        meta: { title: 'Add As Candidate',
        breadcrumb:[
            {name:"Talent Pool", link:"/HR/TalentPoolList"},
            {name: "Add As Candidate"}
        ]
    }
    },
    {
        path: "/HR/ViewTalentPoolDetails/:id/:type",
        name: "HRTalentPoolDetail",
        component: () => import( "../views/HR/TalentPool/ViewTalentPoolDetails.vue"),
        meta: { title: 'Talent pool Details',
        breadcrumb:[
            {name:"Talent Pool", link:"/HR/TalentPoolList"},
            {name: "Add As Candidate"}
        ]
    }
    },
    {
        path: "/JobPost/JobDetail/:id/:type",
        name: "JobPost",
        component: () => import( "../views/HR/JobPost/ViewJobPostDetails.vue"),
        meta: { 
            checkPrivilege: false,
            title: 'Job Details',
            breadcrumb:[
                {name:"JobPost", link:"/JobPost/JobListing"},
                {name: "Manage"}
            ]
        }
    },
    {
        path: "/HR/MakeOffer/:id/:JobId",
        name: "HRMakeOffer",
        component: () => import( "../views/HR/Candidate/MakeOffer.vue"),
        meta: { title: 'Make Offer',
        breadcrumb:[
            {name:"Talent Pool", link:"/HR/CandidateList"},
            {name: "Make Offer"}
        ]
    }
    },
    {
        path: "/HR/MailOffer/:applicantId/:jobid/:TemplateId",
        name: "HREmailOffer",
        checkPrivilege:false,
        component: () => import( "../views/HR/Candidate/MailOffer.vue"),
        meta: { title: 'Mail Offer',
        breadcrumb:[
            {name: "Mail Offer"}
        ]
    }
    },
    {
        path: "/HR/CandidateProfile/:id/:JobId",
        name: "CandidateDetail",
        component: () => import( "../views/HR/Candidate/CandidateProfile/CandidateProfile.vue"),
        meta: { title: 'Candidate Profile',
        breadcrumb:[
            {name:"Candidate List", link:"/HR/CandidateList"},
            {name: "Candidate Profile"}
        ]
    }
    },
    {
        path: "/HR/ForwardCv/:qid?/:aid?/:pid?",
        name: "ForwardCv",        
        component: () => import( "../views/HR/Candidate/ForwardCv.vue"),
        meta: { title: 'Forward Cv',
        breadcrumb:[
            {name: "Forward Cv"}
        ]
    }
    },
    {
        path: "/HR/ManageTalentPool/:reqfrom?/:id?/:pid?/:aid?/:uid?",
        name: "ManageTalentPool",
        component: () => import( "../views/HR/Candidate/AddCandidate.vue"),
        meta: { title: 'Add Candidate',
        breadcrumb:[
            {name:"Talent Pool Listing", link:"/HR/TalentPoolList"},
            {name: "Manage"}
        ]
    }       
    },
    {
        path: "/HR/ScheduleInterview/:aid/:pid",
        name: "ScheduleInterview",
        component: () => import( "../views/HR/Candidate/ScheduleInterview.vue"),
        meta: { title: 'Schedule Interview',
        breadcrumb:[
            {name:"Candidate List", link:"/HR/CandidateList"},
            {name: "Candidate Profile"}
        ]
    }
    },
    {
        path: "/EmployeeSharing/Index",
        name: "EmployeeSharingList",
        component: () => import( "../views/HR/EmployeeSharing/EmployeeSharing.vue"),
        meta: { title: 'Request Employee',
            checkPrivilege: false,
        breadcrumb:[
            {name: "Request Employee"}
        ]
    }
    },
    {
        path: "/ExitPolicy/EmployeeExitListing",
        name: "EmployeeExitListing",
        component: () => import('../views/HR/ExitPolicy/EmployeeExitListing.vue'),
        meta: {
            checkPrivilege: false,
            title: 'Offboard Employee List',
            breadcrumb: [{ name: "Offboard Employee List" }]
        }
    },
    {
        path: "/Policy/PolicyListing",
        name: "PolicyListing",
        component: () => import('../views/HR/Policy/PolicyListing.vue'),
        meta: {
            checkPrivilege: false,
            title: 'Policy Listing',
            breadcrumb: [{ name: "Policy Listing" }]
        }
    },
    {
        path: "/vendoruser/VendorListing",
        name: "VendorListing",
        component: () => import('../views/HR/Vendoruser/VendorListing.vue'),
        meta: {
            checkPrivilege: false,
            title: 'Vendor Listing',
            breadcrumb: [{ name: "Vendor Listing" }]
        }
    },
    {
        path: "/vendoruser/AddVendor",
        name: "AddVendor",
        component: () => import('../views/HR/Vendoruser/AddVendor.vue'),
        meta: {
            userguide: 'User_Guide_vendor_add',
            checkPrivilege: false,
            title: 'Add Vendor',
            breadcrumb: [{ name: "Add Vendor" }]
        }
    },
    {
        path: "/vendoruser/ServicesListing",
        name: "ServicesListing",
        component: () => import('../views/HR/Vendoruser/ServicesListing.vue'),
        meta: {
            checkPrivilege: false,
            title: 'Services Listing',
            breadcrumb: [{ name: "Services Listing" }]
        }
    },
    {
        path: "/HR/ViewTalentPool/:pid?/:sid?/:type?",
        name: "ViewTalentPool",
        component: () => import('../views/HR/JobPost/ViewTalentPool.vue'),
        meta: {
            checkPrivilege: false,
            title: 'Services Listing',
            breadcrumb: [{ name: "Job List" },{ name: "View Talent Pool" }]
        }
    },
    {
        path: "/EmployeeSharing/RequestEmployee/:linkedcompanyID?/:RequestID?",
        name: "RequestEmployee",
        component: () => import('../views/HR/EmployeeSharing/AddEmployeeSharing.vue'),
        meta: {
            checkPrivilege: false,
            title: 'Request Employee',
            breadcrumb: [{ name: "Request Employee" },{ name: "Add Request" }]
        }
    },
    {
        path: "/ExitPolicy/EmployeeResignationList",
        name: "ResignationListing",
        component: () => import('../views/HR/ExitPolicy/EmployeeResignationList.vue'),
        meta: {
            checkPrivilege: false,
            title: 'Resignation',
            breadcrumb: [{ name: "Resignation" }]
        }
    },
    {
        path: "/ExitPolicy/EmployeeExit/:id",
        name: "AddEmployee",
        component: () => import('../views/HR/ExitPolicy/AddEmployee.vue'),
        meta: {
            userguide: 'User_Guide_EmployeeExit',
            checkPrivilege: false,
            title: 'Manage Offboard Employee',
            breadcrumb: [{ name: "Manage Offboard Employee" }]
        }
    },
    {
        path: "/Policy/AddPolicy/:id",
        name: "AddPolicy",
        component: () => import('../views/HR/Policy/AddPolicy.vue'),
        meta: {
            checkPrivilege: false,
            title: 'Manage Policy',
            breadcrumb: [{ name: "Manage Policy" }]
        }
    },
    {
        path: "/EmployeeSharing/ViewRequestEmployeeDetails/:RequestID?",
        name: "EmployeeSharingDetails",
        component: () => import('../views/HR/EmployeeSharing/EmployeeSharingDetails.vue'),
        meta: {
            checkPrivilege: false,
            title: 'Request Employee',
            breadcrumb: [{ name: "Request Employee" }]
        }
    },
    {
        path: "/ExitPolicy/EmployeeResignation/:id",
        name: "EmployeeResignation",
        component: () => import('../views/HR/ExitPolicy/ApplyResignation.vue'),
        meta: {
            checkPrivilege: false,
            title: 'Resignation Form',
            breadcrumb: [{ name: "Resignation" }]
        }
    },
    {
        path: "/vendoruser/AssociatedVendors",
        name: "AssociatedVendors",
        component: () => import('../views/HR/Vendoruser/AssociatedVendors.vue'),
        meta: {
            checkPrivilege: false,
            title: 'Associated Vendors',
            breadcrumb: [{ name: "Search Vendors"},{ name: "Associated Vendors"}]
        }
    },
    {
        path: "/vendoruser/searchvendors",
        name: "searchvendors",
        component: () => import('../views/HR/Vendoruser/SearchVendors.vue'),
        meta: {
            checkPrivilege: false,
            title: 'Search Vendors',
            breadcrumb: [{ name: "Search Vendors"}]
        }
    },
    {
        path: "/ExitPolicy/FeedbackQuestions/:Userid",
        name: "FeedbackQuestions",
        component: () => import('../views/HR/ExitPolicy/FeedbackQuestions.vue'),
        meta: {
            userguide: 'User_Guide_EmployeeExit_feedback',
            checkPrivilege: false,
            title: 'Feedback upon Exit',
            breadcrumb: [{ name: "Feedback upon Exit" }]
        }
    },
    {
        path: "/EmployeeSharing/RequestRaisedForMe",
        name: "RequestRaisedForMe",
        component: () => import('../views/HR/EmployeeSharing/RequestRaisedForMe.vue'),
        meta: {
            checkPrivilege: false,
            title: 'Resource Approval',
            breadcrumb: [{ name: "Resource Approval" }]
        }
    },
    {
        path: "/JobPost/AddPost/:requisitionId?",
        name: "AddPost",
        component: () => import('../views/HR/JobPost/AddPost.vue'),
        meta: {
            checkPrivilege: false,
            title: 'Add Post',
            breadcrumb: [{ name: "Add Post" }]
        }
    },
    {
        path: "/JobPostReport/Index",
        name: "JobPostReport",
        component: () => import('../views/HR/Report/JobPostReport.vue'),
        meta: {
            checkPrivilege: false,
            title: 'Job Post Report',
            breadcrumb: [{ name: "Job Post Report" }]
        }
    },
    {
        path: "/HR/InterviewScheduleNotification/:id/:pid/:isd",
        name: "InterviewScheduleNotification",
        component: () => import('../views/HR/Candidate/InterviewScheduleNotification.vue'),
        meta: {
            checkPrivilege: false,
            title: 'Interview Schedule',
            breadcrumb: [   {name:"Candidate List", link:"/HR/CandidateList"},{ name: "Email" }]
        }
    },
    {
        path: "/HR/LeaveList",
        name: "LeaveList",
        component: () => import( "../views/HR/LeaveList/LeaveList.vue"),
        meta: { title: 'Manage Leave',
            checkPrivilege: false,
        breadcrumb:[
            {name: "Leave List"}
        ]
    }
    },
    {
        path: "/JobPost/jobboard",
        name: "JobBoardSetup",
        component: () => import('../views/HR/JobPost/JobBoard.vue'),
        meta: {
            checkPrivilege: false,
            title: 'Job Board Setup',
            breadcrumb: [{ name: "Job Board Setup" }]
        }
    },
    {
        path: "/HR/Leave",
        name: "ApplyLeave",
        component: () => import( "../views/HR/LeaveList/ApplyLeave.vue"),
        meta: { title: 'Apply Leave',
            checkPrivilege: false,
        breadcrumb:[
            {name: "Leave List" ,link:"/HR/LeaveList"},{name :"Apply Leave"}
        ]
    }
    },
    {
        path: "/Attendance/index",
        name: "AttendanceListing",
        component: () => import('../views/HR/Attendance/AttendanceList.vue'),
        meta: {
            checkPrivilege: false,
            title: 'Manage Attendance',
            breadcrumb: [{ name: "Attendance" }]
        }
    },
    {
        path: "/AttendanceApproval/index",
        name: "ApprovalListing",
        component: () => import('../views/HR/AttendanceApproval/ApprovalListing.vue'),
        meta: {
            checkPrivilege: false,
            title: 'Attendance Approval',
            breadcrumb: [{ name: "Attendance Approval" }]
        }
    },
    {
        path: "/HR/Payroll/:id",
        name: "Payroll",
        component: () => import('../views/HR/LeaveList/Payroll.vue'),
        meta: {
            checkPrivilege: false,
            title: 'Leave List',
            breadcrumb: [{ name: "Leave List" }]
        }
    },
    {
        path: "/HR/LeaveTypeListing",
        name: "LeaveTypeListing",
        component: () => import('../views/HR/LeaveList/LeaveTypeListing.vue'),
        meta: {
            checkPrivilege: false,
            title: 'Leave Type Listing',
            breadcrumb: [{ name: "Leave Type Listing" }]
        }
    },
    {
        path: "/HR/Index",
        name: "AddLeaveType",
        component: () => import('../views/HR/LeaveList/AddLeaveType.vue'),
        meta: {
            checkPrivilege: false,
            title: 'Leave Type Listing',
            breadcrumb: [{ name: "Leave Type Listing" ,link:"/HR/LeaveTypeListing"},{name:"Manage"}]
        }
    },{
        path: "/Attendance/CheckinFlagList",
        name: "CheckinFlagList",
        component: () => import('../views/HR/Attendance/CheckinFlagList.vue'),
        meta: {
            checkPrivilege: false,
            title: 'Flag List',
            breadcrumb: [{ name: "Check-In Flag List" }]
        }
    }, 
    {
        path: "/Attendance/DisputeList",
        name: "DisputeList",
        component: () => import('../views/HR/Attendance/DisputeList.vue'),
        meta: {
            checkPrivilege: false,
            title: 'Dispute List',
            breadcrumb: [{ name: "Dispute List" }]
        }
    },   
    {
        path: "/HrReport/Index",
        name: "HrReport",
        component: () => import('../views/HR/Report/Index.vue'),
        meta: {
            checkPrivilege: false,
            title: 'HR Report',
            breadcrumb: [{ name: "HR Report" }]
        }
    },
    {
        path: "/LeaveApproval/Index",
        name: "LeaveApproval",
        component: () => import('../views/HR/LeaveList/LeaveApproval.vue'),
        meta: {
            checkPrivilege: false,
            title: 'Leave Approval',
            breadcrumb: [{ name: "Approval" ,link:"ApprovalRule/Index"},{ name: "Leave Approval" }]
        }
    },
    {
        path: "/LeaveApproval/History",
        name: "LeaveApprovalHistory",
        component: () => import('../views/HR/LeaveList/History.vue'),
        meta: {
            checkPrivilege: false,
            title: 'Leave Approvals History',
            breadcrumb: [{ name: "Approval" ,link:"ApprovalRule/Index"},{ name: "Leave Approval" ,link:"LeaveApproval/Index"},{name: "History"}]
        }
    },
    {
        path: "/DisputeApproval/Index",
        name: "DisputeApproval",
        component: () => import('../views/HR/Attendance/DisputeApproval.vue'),
        meta: {
            checkPrivilege: false,
            title: 'Dispute Approval',
            breadcrumb: [{ name: "Approval" ,link:"ApprovalRule/Index"},{ name: "Dispute Approval" }]
        }
    },
    {
        path: "/Attendance/UserTrack",
        name: "UserTrack",
        component: () => import('../views/HR/Attendance/UserTrack.vue'),
        meta: {
            checkPrivilege: false,
            title: 'Manage Check-in',
            breadcrumb: [{ name: "Approval" ,link:"ApprovalRule/Index"},{ name: "List Check-In/Out" }]
        }
    },
]
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
}) 
const DEFAULT_TITLE = 'Home';
router.beforeResolve((to, from, next) => {    
    document.title = 'TALYGEN - ' + (to.meta.title || DEFAULT_TITLE);
    document.getElementById("divProgress1").style.display = 'none';
    document.getElementById("talygenfooter").style.display = 'block';
    if (to.path == '/unauthorized') {
        next()
    }
    else {
        const ustore = useloggedUserInfo();
        let app = ustore.GetUserInfo;
        if (app) {
            let isCheckPrivilege = true;
            if(to.meta.hasOwnProperty('checkPrivilege'))
            {
                isCheckPrivilege = to.meta.checkPrivilege;
            }
            if(isCheckPrivilege) {
                let controllerName = "", actionName = "";
                if (to.meta.hasOwnProperty('actionName') && to.meta.hasOwnProperty('controllerName')) {
                    controllerName = to.meta.controllerName;
                    actionName = to.meta.actionName;
                }
                else{
                    let pathArray = to.path.split("/");
                    if(pathArray.length > 2){
                        controllerName = pathArray[1];
                        actionName = pathArray[2];
                    } else{
                        controllerName = pathArray[0];
                        actionName = pathArray[1];
                    }
                }
                if(controllerName != "" && actionName != "") {
                    DataService.CheckPrivilege(`controller=${controllerName}&action=${actionName}`).then((value) => {
                        if (value.data)
                            next()
                        else
                            //common.methods.ShowAlert('Sorry, You are not authorized to access this section.', "warning", true, "Alert");
                            next('/unauthorized');
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                } else {
                    next()
                }
            } else {
                next()
            }
        }
        else {
            //authService.authenticate(to.path).then(() => {
                console.log('authenticating a protected url:' + to.path);
                next();
           // });
        }
    }
});
export default router

